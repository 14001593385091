import { Button } from '@mui/material';

const Payment = () => {
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      if (window.Razorpay) {
        resolve(true);
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };
  const handlePayment = async () => {
    const isLoaded = await loadRazorpayScript();

    if (!isLoaded) {
      alert('Razorpay SDK failed to load. Check your internet connection..');
      return;
    }
    try {
      // Step 1: Get the order ID from Firebase function
      const response = await fetch('https://us-central1-kplcricket-d5078.cloudfunctions.net/createOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amount: 500, currency: 'INR' }) // Replace amount as needed
      });

      const order = await response.json();
      console.log('Order Created: ', order);

      // Step 2: Load Razorpay Checkout
      const options = {
        key: process.env.FIREBASE_RAZORPAY_KEY_ID, // Replace with your test key
        amount: order.amount,
        currency: order.currency,
        name: 'RK TIGERS',
        description: 'Test Transaction',
        order_id: order.id, // Razorpay order ID
        handler: function (response) {
          console.log('Payment Successful: ', response);
          alert('Payment Successful!');
          // You can now save this response in Firestore or your backend
        },
        prefill: {
          name: 'Kiran Kumar S P',
          email: 'spkiran.suresh@gmail.com',
          contact: '9886143737'
        },
        notes: {
          address: 'Bng'
        },
        theme: {
          color: '#3399cc'
        }
      };
      console.log('options ', options);
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  return (
    <Button sx={{ mt: '6%' }} variant='contained' onClick={handlePayment}>
      Make payment here
    </Button>
  );
};

export default Payment;
