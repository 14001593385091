import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Box, Typography } from '@mui/material';
// import { lastDay } from '../../Images';
// import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
export default function VideoOnDialog({ open, handleClose }) {
  // const navigate = useNavigate();
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        maxWidth='md'
        fullWidth
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {open && (
              <Box>
                {/* <video width='100%' controls autoPlay muted>
                  <source src={instaReel} type='video/mp4' />
                </video> */}
                {/* <img src={lastDay} alt='last day' width='100%' /> */}
                <Typography variant='h3'>Live event of KPL 2025 auction.</Typography>
                <iframe
                  width='100%'
                  height='300vh'
                  src='https://www.youtube.com/embed/FPZRdo4OXdk?si=t4FQkNUkql6oFKgj'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  referrerpolicy='strict-origin-when-cross-origin'
                  allowfullscreen
                ></iframe>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            variant='contained'
            sx={{ p: '5px', backgroundColor: '#666', textTransform: 'none' }}
          >
            Close
          </Button>
          {/* <Button
            onClick={() => navigate('/register')}
            variant='contained'
            sx={{ backgroundColor: '#cc0000', fontWeight: 'bold', textTransform: 'none' }}
          >
            Register Now
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
