import InnerBanner from '../components/InnerBanner/InnerBanner';
import RegisterForm1 from '../components/RegisterForm/RegisterForm1';

export default function Registration1() {
  return (
    <>
      <InnerBanner />
      <RegisterForm1 />
    </>
  );
}
